import Marquee3k from "marquee3000";

(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);

  const header = document.querySelector('.main-header');
  const menuTrigger = document.querySelector('.menu-trigger');
  const menuTriggerIcon = document.querySelector('.menu-trigger--icon');
  const menu = document.querySelector('.main-navigation');
  const menuLinks = document.querySelectorAll('.main-navigation__item a');

  function handleDOMContentLoaded() {
    Marquee3k.init();

    menuTrigger.addEventListener('click', handleMenuTrigger);
    menuLinks.forEach((el) => el.addEventListener('click', handleMenuItemClick));
  }

  function handleMenuTrigger(e) {
    e.preventDefault();
    handleMenu();
  }

  function handleMenuItemClick() {
    handleMenu();
  }

  function handleMenu() {
    menuTriggerIcon.classList.toggle('active');
    menu.classList.toggle('main-navigation--visible');
    header.classList.toggle('has-navigation');
  }
})();